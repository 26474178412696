import { lazy, Suspense, useEffect, useState } from "react";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import {
  UserContext,
  attachHandlers,
  getStageType,
} from "@idsk/ui-core-framework";
// import { CreyaContainer } from "./container/CreyaContainer";
// import { CreyaLoginContainer } from "./container/CreyaLoginContainer";
// import { LoginContainer } from "./container/LoginContainer";
// import { RootContainer } from "./container/RootContainer";
import { amplifyConfig } from "./cognito/cognitoconfig";
import { createUserContextFromCognito } from "./cognito/cognitoUtils";
import AWS from "aws-sdk";
import { Spin } from "antd";
import { Amplify, Auth, Hub } from "aws-amplify";
import { getListener } from "./cognito/cognitoEventHandlers";
import {
  ThemeWrapperDataFetcher,
  ThemeWrapperProps,
} from "./container/ThemeWrapper.df";
import { ThemeWrapperLayout } from "./container/ThemeWrapper.layout";
import { region, unAuthCognitoIdentityPoolIds } from "./cognito/constants";
import { CREYA_PUBLIC_AUTH_ROUTES } from "./constants/creyaConstants";

Amplify.configure(amplifyConfig);
const environment = getStageType();
AWS.config.region = region;
const credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: unAuthCognitoIdentityPoolIds[environment],
});

const ThemeWrapperPage = attachHandlers<
  ThemeWrapperProps,
  { children: JSX.Element }
>("ThemeWrapperPage")(ThemeWrapperDataFetcher)(ThemeWrapperLayout);

const CreyaContainer = lazy(() =>
  import("./container/CreyaContainer").then((module) => ({
    default: module.CreyaContainer,
  }))
);
const CreyaLoginContainer = lazy(() =>
  import("./container/CreyaLoginContainer").then((module) => ({
    default: module.CreyaLoginContainer,
  }))
);
const LoginContainer = lazy(() =>
  import("./container/LoginContainer").then((module) => ({
    default: module.LoginContainer,
  }))
);
const RootContainer = lazy(() =>
  import("./container/RootContainer").then((module) => ({
    default: module.RootContainer,
  }))
);

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const defaultUser = process.env.SKIP_LOGIN ? "" : null;
  const [user, setUser] = useState(defaultUser);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);

  const addUnAuthCredentialsToSessionStorage = () => {
    credentials.get((err: AWS.AWSError | undefined) => {
      if (err) {
        console.error("Error getting credentials =", err.message);
        return;
      }
      localStorage.setItem("accessKey", credentials.accessKeyId);
      localStorage.setItem("secretKey", credentials.secretAccessKey);
      localStorage.setItem("sessionToken", credentials.sessionToken || "");
    });
  };

  useEffect(() => {
    const listener = getListener(setUser);
    Hub.listen("auth", listener);
    addUnAuthCredentialsToSessionStorage();
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setUser(currentUser);
        localStorage.setItem(
          "idToken",
          `CognitoIdentityServiceProvider.${amplifyConfig.Auth.userPoolWebClientId}.${currentUser.username}.idToken`
        );
      })
      .catch((err) => {
        console.log(
          "Error occurred, while trying to get authenticated user. Probably the user is not logged in",
          err
        );
      })
      .finally(() => {
        setLoadingUser(false);
      });
  }, []);

  useEffect(() => {
    createUserContextFromCognito(user, amplifyConfig.Auth.userPoolWebClientId);
  }, [user]);

  const values = createUserContextFromCognito(
    user,
    amplifyConfig.Auth.userPoolWebClientId
  );

  useEffect(() => {
    if (loadingUser) return;
    const candidateMatch = matchPath(
      "/creya/session/:sessionId/*",
      location.pathname
    );
    if (candidateMatch) {
      const sessionId = candidateMatch.params?.sessionId;
      if (!values.isSignedIn) {
        const isPublicAuthRoute = CREYA_PUBLIC_AUTH_ROUTES.some((pattern) =>
          matchPath(pattern, location.pathname)
        );
        if (!isPublicAuthRoute) {
          navigate(`/creya/session/${sessionId}/login`);
        }
      } else {
        const baseCandidateRoute = `/creya/session/${sessionId}`;
        if (
          location.pathname === baseCandidateRoute ||
          location.pathname === `${baseCandidateRoute}/`
        ) {
          navigate(`${baseCandidateRoute}/instructions`);
        }
      }
    }
  }, [loadingUser, values.isSignedIn, location.pathname, navigate]);

  if (loadingUser) {
    return (
      <div className="loadingContainer">
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  return (
    <UserContext.Provider value={{ ...values, setUser }}>
      <Suspense>
        <ThemeWrapperPage>
          {location.pathname.includes("/creya") ? (
            !values.isSignedIn ? (
              <CreyaLoginContainer />
            ) : (
              <CreyaContainer />
            )
          ) : !values.isSignedIn ? (
            <LoginContainer />
          ) : (
            <RootContainer />
          )}
        </ThemeWrapperPage>
      </Suspense>
    </UserContext.Provider>
  );
}

export default App;
