import { Auth } from "aws-amplify";
import {
  dev,
  domainToUserPoolId,
  domainToUserPoolWebClientId,
  preprod,
  redirectBaseUrls,
} from "./constants";
import jwt_decode from "jwt-decode";
import { amplifyConfig } from "./cognitoconfig";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib-esm/types";

export interface UserDetails {
  fullname: string;
  name: string;
  username: string;
  password: string;
  newPassword?: string;
  confirmPassword?: string;
  verificationOtp?: string;
}

export function getHttp() {
  return `${window.location.origin}/`;
}

export function getRedirectUrls() {
  const baseOriginUrl = getHttp();

  const redirectUrlOnSignout =
    // @ts-ignore
    redirectBaseUrls[process.env.STAGE] + baseOriginUrl;

  switch (process.env.STAGE) {
    case dev: {
      return {
        domain:
          redirectBaseUrls.dev[
            baseOriginUrl.split("/")[2] as keyof typeof redirectBaseUrls.dev
          ].split("/")[2],
        userPoolId:
          domainToUserPoolId.dev[
            baseOriginUrl.split("/")[2] as keyof typeof domainToUserPoolId.dev
          ],
        userPoolWebClientId:
          domainToUserPoolWebClientId.dev[
            baseOriginUrl.split(
              "/"
            )[2] as keyof typeof domainToUserPoolWebClientId.dev
          ],
        redirectSignIn: baseOriginUrl,
        redirectSignOut: baseOriginUrl,
      };
    }
    case preprod: {
      return {
        domain:
          redirectBaseUrls.preprod[
            baseOriginUrl.split("/")[2] as keyof typeof redirectBaseUrls.preprod
          ].split("/")[2],
        userPoolId:
          domainToUserPoolId.preprod[
            baseOriginUrl.split(
              "/"
            )[2] as keyof typeof domainToUserPoolId.preprod
          ],
        userPoolWebClientId:
          domainToUserPoolWebClientId.preprod[
            baseOriginUrl.split(
              "/"
            )[2] as keyof typeof domainToUserPoolWebClientId.preprod
          ],
        redirectSignIn: baseOriginUrl,
        redirectSignOut: baseOriginUrl,
      };
    }
    default: {
      // By default, always point to prod
      return {
        domain:
          redirectBaseUrls.prod[
            baseOriginUrl.split("/")[2] as keyof typeof redirectBaseUrls.prod
          ].split("/")[2],
        userPoolId:
          domainToUserPoolId.prod[
            baseOriginUrl.split("/")[2] as keyof typeof domainToUserPoolId.prod
          ],
        userPoolWebClientId:
          domainToUserPoolWebClientId.prod[
            baseOriginUrl.split(
              "/"
            )[2] as keyof typeof domainToUserPoolWebClientId.prod
          ],
        redirectSignIn: baseOriginUrl,
        redirectSignOut: baseOriginUrl,
      };
    }
  }
}

export function createUserContextFromCognito(user: any, webClientId: string) {
  if (user === null || user === "") {
    return {
      mail: "",
      clientId: "",
      idToken: "",
      isSignedIn: false,
    };
  }
  let key = `CognitoIdentityServiceProvider.${webClientId}.${user.username}.idToken`;
  const { attributes } = user;
  const idToken = user.storage[key];
  if (idToken === undefined) {
    return { mail: "", clientId: "", idToken: "", isSignedIn: false };
  }
  let decodedToken: any = jwt_decode(idToken);
  const mail = decodedToken.email;
  const clientId = decodedToken["custom:idskClientId"];
  const id = decodedToken["custom:idskUserId"];
  return { idToken, clientId, mail, isSignedIn: true, id, attributes };
}

export const signUp = (values: UserDetails) => {
  return Auth.signUp({
    username: values.username,
    password: values.password,
    attributes: {
      name: values.name,
    },
  });
};

export const signIn = (
  values: UserDetails,
  setUser: React.Dispatch<React.SetStateAction<string | null>>
): Promise<void> => {
  return Auth.signIn(values.username, values.password);
};

export const verifyUserOtp = (
  values: UserDetails,
  setUser: React.Dispatch<React.SetStateAction<string | null>>
) => {
  return Auth.confirmSignUp(values.username, values.verificationOtp!).then(
    async () => {
      await signIn(values, setUser);
      Auth.currentAuthenticatedUser().then((currentUser) => {
        setUser!(currentUser);
        localStorage.setItem(
          "idToken",
          `CognitoIdentityServiceProvider.${amplifyConfig.Auth.userPoolWebClientId}.${currentUser.username}.idToken`
        );
      });
    }
  );
};

export const onFederatedSignIn = (provider: string, custom: boolean) => {
  return custom
    ? Auth.federatedSignIn({ customProvider: provider })
    : Auth.federatedSignIn({
        provider: provider as CognitoHostedUIIdentityProvider,
      });
};

export const forgotPassword = (values: UserDetails) => {
  return Auth.forgotPassword(values.username);
};

export const forgotPasswordSubmit = (values: UserDetails) => {
  return Auth.forgotPasswordSubmit(
    values.username,
    values.verificationOtp!,
    values.newPassword!
  );
};

export const resendOTP = (values: UserDetails) => {
  return Auth.resendSignUp(values.username);
};

export const validatePassword = (value: string) => {
  if (!/[a-z]/.test(value)) {
    return Promise.reject("Password must contain a lower case letter");
  } else if (!/[A-Z]/.test(value)) {
    return Promise.reject("Password must contain an upper case letter");
  } else if (!/\d/.test(value)) {
    return Promise.reject("Password must contain a number");
  } else if (value.length < 8) {
    return Promise.reject("Password must contain at least 8 characters");
  } else if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]/.test(value)) {
    return Promise.reject("Password must contain a special character");
  }
  return Promise.resolve();
};
